import React from 'react'
import {
  OldProductPrice,
  PaymentTypeContainer,
  ProductActionLink,
  ProductActionsContainer,
  ProductCardWrapper,
  ProductImage,
  ProductImageContainer,
  ProductImageHover,
  ProductName,
  ProductPrice,
  RatingContainer,
  StarIcon,
} from './styles'
import { ProductIcon } from 'components/Products/helper'
import tabby from "assets/images/payment-gateways/tabby.svg"
import { ImageLogo } from 'components/Common/Logos'
import { IoSearchOutline } from "react-icons/io5";
import { LiaCartArrowDownSolid } from "react-icons/lia";
import Star from "assets/images/general/Star.svg"
import { useTranslation } from 'react-i18next';
import { isTabbyCountry } from 'utils/helpers'
import Cookies from 'js-cookie'


function ProductCard({ product, handleAddToCart, setViewSingleProduct, navigate }) {
  const { t, i18n } = useTranslation();
  const country = Cookies.get('country');
  const eligibleForTabby = product.tabby_product && isTabbyCountry(country);
  return (
    <ProductCardWrapper key={product.id}>
      <ProductImageContainer>
        <ProductImage
          src={product.images.length >= 0 ? product.images[0].file_url : ''}
          alt={product.title[i18n.language]}
          onClick={() => {
            navigate(`/products/${product.url_identifier}`)
          }}
        />
        <ProductImageHover
          src={product.images.length >= 1 ? product.images[1].file_url : ''}
          alt={product.title[i18n.language]}
          onClick={() => {
            navigate(`/products/${product.url_identifier}`)
          }}
        />
        <ProductActionsContainer>
          {/* <ProductActionLink
            onClick={() => {
              handleAddToCart(product)
            }}
          >
            <LiaCartArrowDownSolid fontSize='larger' />
          </ProductActionLink> */}
          <ProductActionLink
            onClick={() => {
              setViewSingleProduct(product.url_identifier)
            }}
          >
            <IoSearchOutline fontSize='larger' />
          </ProductActionLink>
        </ProductActionsContainer>
        <ProductIcon product={product} selectedOption={0}/>
        {eligibleForTabby ?<PaymentTypeContainer>
          <ImageLogo alt='tabby' src={tabby} width='50px' />
        </PaymentTypeContainer>: null}
      </ProductImageContainer>
      <ProductName onClick={() => {
            navigate(`/products/${product.url_identifier}`)
          }}>{product?.title[i18n.language]}</ProductName>
      <RatingContainer>
        {[...Array(5)].map((_, index) => (
          <StarIcon key={index}>
            <ImageLogo alt='Star' src={Star} />
          </StarIcon>
        ))}
      </RatingContainer>
      <ProductPrice>
        {t(`currencies.${product.currency}`)} {product.price}
        {product.is_on_sale ? <OldProductPrice>{product.original_price}</OldProductPrice>: null}
      </ProductPrice>
    </ProductCardWrapper>
  )
}

export default ProductCard
