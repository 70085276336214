import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import styled from "styled-components";
import i18n from "utils/i18n";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 20px;
`;

const Label = styled.label`
  color: #4a4a4a;
  font-weight: 700;
  font-size: 0.875rem;
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 12rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s;
  &:hover {
    background-color: #f9fafb;
  }
`;

const Icon = styled(IoMdArrowDropup)`
  width: 1rem;
  height: 1rem;
  transition: transform 0.2s;
  transform: ${({ isOpen }) => (isOpen ? "rotate(0deg)" : "rotate(180deg)")};
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 0.25rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Option = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #ff2d47;
    color: white;
  }
  &:focus {
    outline: none;
    background-color: #f3f4f6;
  }
`;

export default function OptionSelector({options, selectedOption, handleChangeOption}) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  if(options.length === 0){
    return null;
  }


  return (
    <Container>
      <Label>{t('select')} {options[0].option_name[i18n.language]}</Label>
      <DropdownContainer>
        <Button onClick={() => setIsOpen(!isOpen)}>
          <span>{options[selectedOption].option_value[i18n.language]}</span>
          <Icon isOpen={isOpen} />
        </Button>
        {isOpen && (
          <Dropdown>
            {options.map((option, index) => (
              <Option
                key={option}
                onClick={() => {
                  handleChangeOption(index);
                  setIsOpen(false);
                }}
              >
                {option.option_value[i18n.language]}
              </Option>
            ))}
          </Dropdown>
        )}
      </DropdownContainer>
    </Container>
  );
}
