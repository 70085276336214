import styled from 'styled-components'
import { X } from 'lucide-react'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 600px;
  position: relative;
`

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
`

const Title = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`

const Subtitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 2rem;
`

const StepCard = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

const StepText = styled.p`
  margin: 0;
  font-size: 0.9375rem;
`

const SmallText = styled.p`
  color: #666;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 0;
`

const ViewFAQ = styled.a`
  color: #0066cc;
  text-decoration: none;
  display: block;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
`


export default function SubscriptionFAQ({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <X size={24} />
        </CloseButton>
        
        <Title>Auto-Replenish</Title>
        <Subtitle>How it works</Subtitle>

        <StepCard>
          <img 
            src="/placeholder.svg?height=48&width=48" 
            alt="Select product" 
            width={48} 
            height={48}
          />
          <StepText>
            Select the Auto-Replenish option on the product page and choose how often you'd like to receive the product.
          </StepText>
        </StepCard>

        <StepCard>
          <img 
            src="/placeholder.svg?height=48&width=48" 
            alt="Save money" 
            width={48} 
            height={48}
          />
          <div>
            <StepText>
              Get 5-15%* off subscription deliveries and free shipping every time.
            </StepText>
            <SmallText>
              *Terms vary. See product for details.
            </SmallText>
          </div>
        </StepCard>

        <StepCard>
          <img 
            src="/placeholder.svg?height=48&width=48" 
            alt="Manage subscription" 
            width={48} 
            height={48}
          />
          <StepText>
            Pause, skip, cancel, or adjust your delivery frequency at any time.
          </StepText>
        </StepCard>

        <ViewFAQ href="#">View FAQ</ViewFAQ>
      </ModalContent>
    </ModalOverlay>
  )
}

