import { Button } from 'components/Common/Buttons'
import { LinkWithHover } from 'components/Common/Links'
import { ImageLogo } from 'components/Common/Logos'
import { Container, Content } from 'components/Common/Wrapper'
import Footer from 'components/GeneralLayout/Footer'
import Menu from 'components/GeneralLayout/Menu'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdArrowDropdown, IoMdArrowDropup, IoIosTrash } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import useCartStore from 'stores/cartStore'
import useUserStore from 'stores/userStore'
import styled, { useTheme } from 'styled-components'


const CartTitle = styled.h3`
    background: unset;
    border: 2px solid #333333;
    border-radius: 50px;
    position: absolute;
    font-size: 14px;
    background-color:white;
    padding: 10px 20px;
    line-height: 25px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
`;

const AddToCartWrapper = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 4px;
`
const AddQuantityWrapper = styled.div`
    font-size: 16px;
    width: 100px;
    text-align: center;
    position: relative;
`
const AddQuantityButton = styled.button`
  background-color: unset;
  border: none;
  ${props => props.$right ? 'right: 0;' : 'left: 0;'}
  top: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  &:hover {
    ${props => props?.$disabled ? '' : 'opacity: 0.5;'}
  }
`
const ItemCart = styled.div`
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 30px 0px;

`
const ProductName = styled.div`
  display: flex;
  width: 40%;
  gap:5%;
  align-items: center;
`
const ProductImage = styled.div`
  display: flex;
  gap:5%;
  align-items: center;
`
const CartWrapper = styled.div`
    display: flex;
    gap: 60px 5%;
    width: 90%;
    margin: auto;
    @media screen and (max-width: 1000px){
      flex-wrap:wrap;
    }
    @media screen and (max-width: 450px){
      width: 100%;
    }
    margin-bottom: 100px;

`
const ProductSummaryWrapper = styled.div`
    flex: 1 0 65%;
    border: 2px solid #f0f0f0;
    position: relative;
`
const CartSummaryWrapper = styled.div`
    flex: 1 0 33%;
    height: 350px;
    border: 2px solid #f0f0f0;
    position: relative;
    background: ${props => props.theme.colors.cultured};
    height: max-content;
`
const CartNote = styled.textarea`
    display: block;
    width: 80%;
    height: 200px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    margin: auto;
`

const CartLabel = styled.label`
  margin-bottom: 10px;
  font-family: ${props => props.theme.fontFamily.en_semiBold};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
`

const Lomoney = styled.span`
  font-size: 16px;
  font-family: ${props => props.theme.fontFamily.en_semiBold};
  .ar & {
    font-family: ${props => props.theme.fontFamily.ar_bold};
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  padding: 2rem;
  gap: 5px;
  @media screen and (max-width: 600px){
    flex-direction: column;
  }
`

const RemoveButton = styled.a`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

function Cart() {
  const { t } = useTranslation();
  let storedCart = useCartStore((state)=> state.cart)
  const [newCart, setNewCart] = useState(storedCart);
  const [cartNotes, setCartNotes] = useState(useCartStore((state)=> state.cart_note));
  const updateCart = useCartStore((state)=> state.updateCart)
  const updateCartNote = useCartStore((state)=> state.updateCartNote)
  const getTotalPrice = useCartStore((state)=> state.getTotalPrice)
  const getSubscriptionsPrice = useCartStore((state) => state.getSubscriptionsPrice);
  const totalPrice = useMemo(() => getTotalPrice() + getSubscriptionsPrice(), []);
  const cartCurrency = useCartStore((state)=> state.currency)
  const  lang = useUserStore((state) => state.lang);
  
  const theme = useTheme();
  const navigate = useNavigate();
  const UpdateCartCheckout = () =>{
    updateCart(newCart)
    window.scrollTo(0,0); 
    window.location.reload();
  }
  const setNewProductQuantity = (index,amount)=>{
    setNewCart((prev)=>{
      let updatedCart = [...prev];
      updatedCart[index].quantity += amount;
      return updatedCart;
    })
  }
  const removeProduct = (index)=>{
    setNewCart((prev)=>{
      let updatedCart = [...prev];
      updatedCart.splice(index,1);
      return updatedCart;
    })
  }
  const saveCartNote = ()=>{
    updateCartNote(cartNotes)
    navigate('/checkout')
  }
  return (
    <Container>
      <Menu />
      <Content $overRideMargin={'200px'} $overRideMarginMobile={'130px'}>
        {storedCart.length === 0 ? (<div style={{height:"500px",textAlign:"center"}}><p style={{fontSize:"50px",lineHeight:"62px"}}>{t('cart-empty')}</p></div>) :
        <CartWrapper>
          <ProductSummaryWrapper>
            <div
              style={{
                position: 'relative',
                top: '-30px',
                width: '100%',
                textAlign: 'center',
                height:"50px"
              }}
            >
              <CartTitle>{t('cart-breakdown')}</CartTitle>
            </div>
            <div style={{ display: 'flex', flexDirection:"column", }}>
              {newCart.map((cartItem,ind) => (
                <ItemCart>
                <ProductName>
                    <ProductImage >
                    <ImageLogo
                      width={'30%'}
                      style={{border: '1px solid #ebebeb',borderRadius:"7px"}}
                      alt='store.basma.com'
                      src={cartItem.images[0].file_url}
                    />
                    <div><LinkWithHover $fontSize={"16px"} $color={"#000"} $textTransform={"uppercase"}>{cartItem.title[lang]}{cartItem.subscription ?` - ${t('subscription')}` : ""}</LinkWithHover></div>
                    </ProductImage>
                  </ProductName>
                    <AddToCartWrapper>
                      <AddQuantityWrapper>
                        {!cartItem.subscription? <AddQuantityButton
                          $disabled={cartItem.quantity == 1}
                          disabled={cartItem.quantity == 1}
                          onClick={() => {
                            setNewProductQuantity(ind,-1)
                          }}
                        >
                          <IoMdArrowDropdown fontSize='x-large' />
                        </AddQuantityButton> : null}
                        <span>{cartItem.quantity}</span>
                        {!cartItem.subscription? <AddQuantityButton
                          $right
                          onClick={() => {
                            setNewProductQuantity(ind,1)
                          }}
                        >
                          <IoMdArrowDropup fontSize='x-large' />
                        </AddQuantityButton> : null}
                      </AddQuantityWrapper>
                    </AddToCartWrapper>
                  <div>
                    <Lomoney >{t('price-total',{currency: cartCurrency, price: cartItem.quantity * cartItem.price})}</Lomoney>
                  </div>
                  <RemoveButton>
                    <IoIosTrash fontSize="x-large" onClick={() => {
                            removeProduct(ind)
                          }} />
                  </RemoveButton>
                </ItemCart>
              ))}
              <ButtonsWrapper>
                <Button $padding={"13px"} $width={"100%"} $widthMobile={"80%"}  $borderRadius={'5px'} $height='unset' onClick={()=>{navigate("/")}}>{t('continue-shopping')}</Button>
                <Button $padding={"13px"} $width={"100%"} $widthMobile={"80%"}  $borderRadius={'5px'} $height='unset' $background={theme.colors.black} onClick={UpdateCartCheckout}>{t('update-cart')}</Button>
              </ButtonsWrapper>
            </div>
          </ProductSummaryWrapper>
          <CartSummaryWrapper
          >
            <div
              style={{
                position: 'relative',
                top: '-30px',
                width: '100%',
                textAlign: 'center',
                height:"50px"
              }}
            >
              <CartTitle>{t('cart-total')}</CartTitle>
            </div>
            <div style={{ marginBottom:"30px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                <h4>{t('cart-note')}</h4>
                <CartLabel for='cartTextarea'>{t('special-instructions')}</CartLabel>
                <CartNote id="cartTextarea" rows="6" value={cartNotes} onChange={(e)=>{setCartNotes(e.target.value)}}></CartNote>
                <h3>{t('total')}: {t('price-total',{currency: cartCurrency, price: totalPrice})}</h3>
                <Button $padding={"20px"} $borderRadius={'30px'} onClick={saveCartNote}>{t('proceed-to-checkout')}</Button>
              </div>
          </CartSummaryWrapper>
        </CartWrapper>
            }
      </Content>
      <Footer />
    </Container>
  )
}

export default Cart