import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from 'components/StripeCheckoutForm';
import { useCheckout } from 'hooks/reactQuery/useCheckoutQuery';
import useCartStore from 'stores/cartStore';
import { loadStripe } from '@stripe/stripe-js';
import { ImageLogo } from 'components/Common/Logos';
import stripe from "assets/images/payment-gateways/stripe.svg";
import tabby from "assets/images/payment-gateways/tabby.svg"
import { isTabbyCountry } from 'utils/helpers';
import useUserStore from 'stores/userStore';
import { PayButton } from 'components/Common/Buttons';
import { useCouponValidation } from 'hooks/reactQuery/UseCouponsQuery';
import { useFreeShipping, useShipping } from 'hooks/reactQuery/useShippingQuery';
import Cookies from 'js-cookie';
import SendWithDispatch from './SendWithDispatch';
import { toast } from 'react-toastify';
import i18n from 'utils/i18n';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const Payment = ({
    clientSecret,
    setClientSecret,
    total,
    currency,
}) => {
    const { t } = useTranslation();
    const { dispatch, checkDispatch, isPending } = useFreeShipping()
    const [showDispatch, setShowDispatch] = useState(true);
    const turnOffDispatch = () => {
        setShowDispatch(false)
    }
    const { checkout, isPending: isPendingCheckout, } = useCheckout();
    const [paymentType, setPaymentType] = useState('stripe');
    const cart = useCartStore((state) => state.cart);
    let hasTabby = useMemo(() => cart.findIndex((cartItem) => cartItem.tabby_product), []);
    const country = Cookies.get('country');
    const guest = useUserStore((state) => state.guest);
    const eligibleForTabby = useMemo(() => isTabbyCountry(country), [country]);
    const { isPending: isPendingCoupon } = useCouponValidation();
    const { isLoading: isLoadingShipping } = useShipping();

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
            // See all possible variables below
        }
    };
    const options = {
        clientSecret,
        appearance,
    };
    useEffect(()=>{
        checkDispatch()
    },[])

    const handleCheckout = async (e) => {
        e.preventDefault();
        checkout(
            {
                paymentType
            },
            {
                onSuccess: async (data) => {
                    if (data.type === "stripe") {
                        setClientSecret(data.data.clientSecret)
                    } else if (data.type === "tabby") {
                        window.location.href = data.data;
                    }
                },
                onError: async (error) => {
                    const errorDetails = JSON.parse(error.message); 
                    console.log(errorDetails)
                    if(errorDetails.message === "max_subscription_product_quantity_exceeded"){
                        toast(t('error.max_subscription_product_quantity_exceeded'),{type:"warning",autoClose: 10000, rtl:i18n.language == 'ar'? true : false});
                    }
                }
            }
        );
    }

    return (
        <>
            <span className='checkout-payment-description-span'>{t('secure-transactions')}</span>
            {
                clientSecret ?
                <div className='checkout-payment-wrapper'>
                    <Elements options={options} stripe={stripePromise}>
                        <StripeCheckoutForm />
                    </Elements>
                </div> :
                <div className='checkout-payment-wrapper'>
                    <div className='payment-gateway-option'>
                        <input
                            type="radio"
                            id="stripe"
                            name="payment-gateway"
                            value="stripe"
                            disabled={isPendingCheckout}
                            checked={paymentType === 'stripe'}
                            onChange={(e) => setPaymentType(e.target.value)}

                        />
                        <label htmlFor="stripe">
                            <div className='checkout-payment-method-wrapper'>
                                <span className='checkout-payment-method-span'>{t('stripe-one-time-payment', { currency: t(`currencies.${currency}`), price: total })} </span>
                                <ImageLogo alt='stripe' src={stripe} width='50px' />
                            </div>
                        </label>
                    </div >
                    {!guest && hasTabby != -1 && eligibleForTabby ? <div className='payment-gateway-option'>
                        <input
                            type="radio"
                            id="tabby"
                            name="payment-gateway"
                            value="tabby"
                            disabled={isPendingCheckout}
                            checked={paymentType === 'tabby'}
                            onChange={(e) => setPaymentType(e.target.value)}
                        />
                        <label htmlFor="tabby">
                            <div className='checkout-payment-method-wrapper'>
                                <span className='checkout-payment-method-span'>{t('tabby-payments', { currency: t(`currencies.${currency}`), price: Math.ceil(total / 4) })}</span>
                                <ImageLogo alt='tabby' src={tabby} width='50px' />
                            </div>
                        </label>
                    </div> : null}
                    <PayButton disabled={isPendingCoupon || isPendingCheckout || isLoadingShipping || isPending} $padding={"15px"} $width={"100%"} $borderRadius={'5px'} $margin={'2em auto auto auto'} onClick={(e) => {handleCheckout(e)}}>{t('pay-now')}</PayButton>
                </div>}
                {dispatch?.id && showDispatch ? <SendWithDispatch dispatch={dispatch} turnOffDispatch={turnOffDispatch} /> : null}
        </>
    )
}

export default Payment