import { useState } from 'react'
import styled from 'styled-components'
import { Truck, RefreshCcw, Box, Store } from 'lucide-react'
import SubscriptionFAQ from './SubscriptionFAQ'
import { useTranslation } from 'react-i18next'
import i18n from 'utils/i18n'

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 20px;
  ${props => props.$inModal ? 
    'grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));'
    : ""
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
`

const DetailsContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f6f6f8;
`

const FeatureCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  border: 1px solid ${props => props.$isSelected ? '#000' : '#e5e5e5'};
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  background: ${props => props.$isSelected ? '#f8f8f8' : '#fff'};
  min-height: 50px;
  &:hover {
    border-color: #000;
  }
`
const BestValueTag = styled.div`
  background: #ffdadf;
  color: #ff2d47;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
  margin-top: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  ${props => props.$inModal ? 
    'font-size: 0.65rem;'
    : ""
  }
  
`
// position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translateX(-50%);

const IconWrapper = styled.div`
  margin-bottom: 0.5rem;
  color: #666;
`

const FeatureTitle = styled.div`
  font-size: 0.8125rem;
  line-height: 1.2;
`

const SaveText = styled.div`
  font-size: 0.75rem;
  color: #666;
  margin-top: 0.25rem;
`

const PriceContainer = styled.div`
  marginBottom: 1rem;
`

const Price = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
`

const SaveLabel = styled.span`
  color: #666;
  margin-left: 0.25rem;
`

const OriginalPrice = styled.span`
  color: #666;
  text-decoration: line-through;
  margin-left: 0.25rem;
`

const DeliveryInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
`

const ConsentText = styled.p`
  color: #666;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`

const Link = styled.a`
  color: #0066cc;
  text-decoration: none;
  font-size: 0.75rem;

  &:hover {
    text-decoration: underline;
  }
`

export default function SubscriptionOption({price, $inModal, typeOfPurchase, changeTypeOfPurchase}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslation();
  let percentageSaved = Math.ceil(100 - (price.subscription_price/price.price)*100)
  let subscriptionDetails = {
     "bulk": {
        showPrice: false,
        details : t('bulk-details'),
        showLink: false,//dont use it
        showTerms: false
      },
      "subscription": {
        showPrice: true,
        deliveryInfo : t('subscription-delivery'),
        details : t('subscription-details'),
        consentText: true,
        showLink: false,//dont use it
        showTerms: true
      }
  }
  let subscriptionDetail = subscriptionDetails[typeOfPurchase]
  
  
  return (
    <div>
      <FeaturesContainer $inModal={$inModal}>
        <FeatureCard $isSelected={typeOfPurchase=="bulk"} onClick={()=>{changeTypeOfPurchase("bulk")}}>
          <IconWrapper>
            <Truck size={20} />
          </IconWrapper>
          <FeatureTitle>
            {t('single-order-title')}
          </FeatureTitle>
          <SaveText>{t('single-order-description')}</SaveText>
        </FeatureCard>

        <FeatureCard $isSelected={typeOfPurchase=="subscription"} onClick={()=>{changeTypeOfPurchase("subscription")}}>
          <IconWrapper>
            <RefreshCcw size={20} />
          </IconWrapper>
          <FeatureTitle>{t('subscription-order-title')}</FeatureTitle>
          <SaveText>{t('subscription-order-description')} {percentageSaved}%</SaveText>
          <BestValueTag $inModal={$inModal}>{t('best-value')}</BestValueTag>
        </FeatureCard>
      </FeaturesContainer>

      <DetailsContainer>
        {subscriptionDetail.showPrice?
          <PriceContainer>
            <Price>{price.currency} {price.subscription_price}</Price>
            <SaveLabel>({t('save')} {percentageSaved}%)</SaveLabel>
            <OriginalPrice>{price.price}</OriginalPrice>
          </PriceContainer> :null}

        {subscriptionDetail.deliveryInfo?<DeliveryInfo>
            {subscriptionDetail.deliveryInfo}
          </DeliveryInfo>:null}
          {subscriptionDetail.details?<DeliveryInfo>
            {subscriptionDetail.details}
          </DeliveryInfo>:null}
          {subscriptionDetail.consentText?
            <ConsentText>
              {(t('subscription-consent'))}
            </ConsentText>:null}
        
            {subscriptionDetail.showLink?
              <Link href="#" onClick={(e) => {
                e.preventDefault()
                setIsModalOpen(true)
              }}>
                About Auto-Replenish
              </Link>
            :null}
            {subscriptionDetail.showTerms?
              <Link href={`https://basma.com/${i18n.language}/terms-and-conditions`} target="_blank" rel="noopener noreferrer">
                {t('terms-and-conditions')}
              </Link>
            :null}
      </DetailsContainer>
      

      <SubscriptionFAQ 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  )
}

