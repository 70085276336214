import { ImageLogo } from 'components/Common/Logos';
import { useAutomaticCoupons, useCouponValidation } from 'hooks/reactQuery/UseCouponsQuery';
import { useShipping } from 'hooks/reactQuery/useShippingQuery';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import useCartStore from 'stores/cartStore';
import useUserStore from 'stores/userStore';

const CartBreakdown = ({
    clientSecret,
    totalPrice,
    totalDiscount,
    total,
    shippingPrice,
    currency,
}) => {
    const { t } = useTranslation();
    const cart = useCartStore((state) => state.cart);
    const lang = useUserStore((state) => state.lang);
    const send_with_dispatch = useCartStore((state)=> state.send_with_dispatch)
    const { shipping: shippingCoupon, cart: cartCoupon } = useCartStore((state) => state.coupons);
    const [couponCode, setCouponCode] = useState('');
    const { isError, isPending: isPendingCoupon, validateCoupon } = useCouponValidation();
    const { isPending: isPendingAutomaticCoupon, getAutomaticCoupons } = useAutomaticCoupons();
    const clearCoupon = useCartStore((state) => state.clearCoupon);
    const { isError: ShippingIsError, isLoading: ShippingIsLoading} = useShipping();
    const loginActive = ShippingIsError || ShippingIsLoading;

    const calculateDiscount = (original) => {
        if (!cartCoupon?.coupon?.code) {
            return 0;
        }
        if (cartCoupon.coupon.type_of_discount == "amount") {
            return cartCoupon.coupon.amount
        } else {
            return Math.floor((original * cartCoupon.coupon.amount) / 100)
        }
    }

    const handleCouponCodeChange = (e) => {
        setCouponCode(e.target.value)
    };

    const handleApplyCoupon = async () => {
        await validateCoupon(couponCode)
        setCouponCode('');
    };

    return (
        <div className="cart-breakdown">
            <div className="coupon-section">
                <h2>{t('cart-breakdown')}</h2>
                {
                    cart.map((item, key) => {
                        return (
                            <div key={key} className='checkout-cart-item'>
                                <div className='checkout-cart-item-img-wrapper'>
                                    <span className='checkout-cart-item-quantity'>{item.quantity}</span>
                                    <ImageLogo alt={item.title?.[lang]} src={item.images[0].file_url} width='50px' style={{ borderRadius: '5px' }} />
                                </div>
                                <div className='checkout-cart-item-breakdown'>
                                    <span className='checkout-cart-item-title'>{item.title?.[lang]}{item.subscription ?` - ${t('subscription')}` : ""}</span>
                                    {
                                    cartCoupon?.coupon?.code && cartCoupon?.type != "cart" && cartCoupon?.coupon?.product_id == item.id ?
                                    <span className='checkout-cart-item-price-s'>
                                        {`${t(`currencies.${currency}`)}`} <s>{item.price}</s> {`${item.price - calculateDiscount(item.price)} x${item.quantity}`}
                                    </span>
                                    :
                                    <span className='checkout-cart-item-price-s'>{`${t(`currencies.${currency}`)} ${item.price} x${item.quantity}`}</span>
                                }
                                </div>
                                {
                                    cartCoupon?.coupon?.code && cartCoupon?.type != "cart" && cartCoupon?.coupon?.product_id == item.id ?
                                    <span className='checkout-cart-item-price'>
                                        <s>{`${t(`currencies.${currency}`)} ${item.quantity * item.price}`}</s> {`${item.quantity * item.price - calculateDiscount(item.quantity * item.price)}`}
                                    </span>
                                    :
                                    <span className='checkout-cart-item-price'>{`${t(`currencies.${currency}`)} ${item.quantity * item.price}`}</span>
                                }
                            </div>
                        )
                    })
                }
                <div className='checkout-coupon-form'>
                    <input
                        type="text"
                        className='checkout-input'
                        name="couponCode"
                        onChange={handleCouponCodeChange}
                        placeholder={loginActive ?  t("login-first"): t('enter-coupon')}
                        value={couponCode}
                        disabled={loginActive || isPendingCoupon || isPendingAutomaticCoupon || clientSecret}
                    />
                    <button className='checkout-coupon-button' disabled={loginActive || isPendingCoupon || isPendingAutomaticCoupon || clientSecret} onClick={handleApplyCoupon}>{t('apply')}</button>
                </div>
                {isError ? <p className='checkout-coupon-error'>{t('invalid-coupon')}</p> : null}
                <div className='coupon-summary-wrapper'>
                    {cartCoupon?.coupon?.code ? <div className='coupon-summary' >
                        <span className='coupon-summary-code'>{cartCoupon.coupon.code}</span>
                        <span className='coupon-summary-code code-clear' onClick={() => { if (clientSecret) { return } clearCoupon("cart") }}>X</span>
                    </div> : null}
                    {
                        shippingCoupon?.code ?
                        <div className='coupon-summary'>
                            <span className='coupon-summary-code'>{shippingCoupon.code}</span>
                            <span className='coupon-summary-code code-clear' onClick={() => { clearCoupon("shipping") }}>X</span>
                        </div> :
                        null
                    }
                </div>
                <div className='checkout-summary-wrapper'>
                    <span className='checkout-subtotal'>{t('subtotal')}</span>
                    <span className='checkout-subtotal'>{t(`currencies.${currency}`)} {totalPrice}</span>
                </div>
                <div className='checkout-summary-wrapper'>
                    <span className='checkout-subtotal'>{t('order-discount')}</span>
                </div>
                {cartCoupon?.coupon?.code ? <div className='checkout-summary-wrapper'>
                    <span className='checkout-subtotal' ></span>
                    <span className='checkout-subtotal'>- {t(`currencies.${currency}`)} {totalDiscount}</span>
                </div> : null}
                <div className='checkout-summary-wrapper'>
                    <span className='checkout-shipping'>{t('shipping')}</span>
                    {
                        shippingCoupon?.code || send_with_dispatch ?
                        <span className='checkout-shipping'><s>{shippingPrice ? t(`currencies.${currency}`) + " " + shippingPrice : ""}</s> {t('free')}</span> :
                        shippingPrice ?
                            <span className='checkout-shipping'>{t(`currencies.${currency}`)} {shippingPrice}</span> :
                            null
                    }
                </div>
                <div className='checkout-summary-wrapper'>
                    <span className='checkout-total'>{t('total')}</span>
                    <span className='checkout-total'>{t(`currencies.${currency}`)} {total}</span>
                </div>
            </div>
        </div>
    )
}

export default CartBreakdown