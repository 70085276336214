import { Container } from 'components/Common/Wrapper';
import Menu from 'components/GeneralLayout/Menu';
import React, { useMemo, useState } from 'react';
import useCartStore from 'stores/cartStore';
import 'react-phone-number-input/style.css'
import 'checkout.css';
import Footer from 'components/GeneralLayout/Footer';
import { convertToCurrency } from 'utils/helpers';
import CheckoutForms from 'components/Checkout/CheckoutForms';

const Checkout = () => {
    const shipping = useCartStore((state) => state.shipping);
    const cart = useCartStore((state) => state.cart);
    const { shipping: shippingCoupon, cart: cartCoupon } = useCartStore((state) => state.coupons);
    const [clientSecret, setClientSecret] = useState("");
    const changeClientSecret = (newClientSecret) => {
        setClientSecret(newClientSecret);
    };
    const calculateTotalDiscount = (original) => {
        if (!cartCoupon?.coupon?.code) {
            return 0;
        }
        if (cartCoupon.type != "cart") {
            let item = cart.find((cartItem) => cartItem.id == cartCoupon.coupon.product_id)
            if (cartCoupon.coupon.type_of_discount == "amount") {
                return Math.min(item.price * item.quantity, cartCoupon.coupon.amount)
            } else {
                let discount = Math.floor((item.price * item.quantity * cartCoupon.coupon.amount) / 100)
                return Math.min(item.price * item.quantity, discount)
            }
        }
        else {
            if (cartCoupon.coupon.type_of_discount == "amount") {
                return cartCoupon.coupon.amount
            } else {
                return Math.floor((original * cartCoupon.coupon.amount) / 100)
            }
        }
    }
    const currency = useCartStore((state) => state.currency);
    const send_with_dispatch = useCartStore((state) => state.send_with_dispatch);
    const shippingPrice = useMemo(() => convertToCurrency(shipping?.amount, currency), [shipping]);
    const getTotalPrice = useCartStore((state) => state.getTotalPrice);
    const getSubscriptionsPrice = useCartStore((state) => state.getSubscriptionsPrice);
    const totalPrice = useMemo(() => getTotalPrice() + getSubscriptionsPrice(), [cart]);
    const bulkOrderPrice = useMemo(() => getTotalPrice(), [cart]);
    const totalDiscount = useMemo(() => calculateTotalDiscount(bulkOrderPrice), [bulkOrderPrice, cartCoupon]);
    const total = useMemo(() => Math.floor(totalPrice + (shippingCoupon || send_with_dispatch ? 0 : shippingPrice) - totalDiscount), [totalDiscount, shippingCoupon, shippingPrice, totalPrice, send_with_dispatch]);

    return (
        <Container>
            <Menu />
            {/* <LoginForm /> */}
            <CheckoutForms
                clientSecret={clientSecret}
                setClientSecret={changeClientSecret}
                total={total}
                totalPrice={totalPrice}
                totalDiscount={totalDiscount}
                shippingPrice={shippingPrice}
                currency={currency}
            />
            <Footer />
        </Container>
    );
};

export default Checkout;
