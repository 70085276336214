import { useQuery, useMutation } from "@tanstack/react-query";
import { getAutomaticCoupons, validateCoupon } from "apis/coupons";
import useCartStore from "stores/cartStore";


export const useCouponValidation = () => {
    const cart = useCartStore((state)=> state.cart);
    const applyCoupon = useCartStore((state)=> state.applyCoupon);
    const currency = useCartStore((state)=> state.currency);
    const { data, error, isError, isPending, mutateAsync } = useMutation({
        mutationKey: ["coupon"],
        mutationFn: (code) => validateCoupon(code, cart, currency),
        onSuccess: (data) => {
            applyCoupon(data.coupon, data.type)
        }
    });
    return { coupon: data ?? {}, isPending, error, isError, validateCoupon: mutateAsync}
};

export const useAutomaticCoupons = () => {
    const cart = useCartStore((state)=> state.cart);
    const applyCoupon = useCartStore((state)=> state.applyCoupon);
    const { data, error, isError, isPending, mutateAsync } = useMutation({
        mutationKey: ["coupon"],
        mutationFn: () => getAutomaticCoupons(cart),
        onSuccess: (data) => {
            if(data.coupons?.shipping?.code){
                let type = data.coupons?.shipping.type;
                let coupon = data.coupons?.shipping;
                delete coupon["type"]; 
                applyCoupon(data.coupons?.shipping, type)
            }
            if(data.coupons?.cart?.code){
                let type = data.coupons?.cart.type;
                let coupon = data.coupons?.cart;
                delete coupon["type"];
                applyCoupon(data.coupons?.cart, type)
            }
        }
    });
    return { coupon: data ?? {}, isPending, error, isError, getAutomaticCoupons: mutateAsync}
};
